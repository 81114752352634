import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeFormat',
  standalone: true
})
export class TimeFormatPipe implements PipeTransform {

  transform(value: string): string {
    if (!value) return value;

    // Divida o valor pelo delimitador ':' para obter horas e minutos
    const timeParts = value.split(':');

    const hours = timeParts[0];
    const minutes = timeParts[1];

    // Retorna no formato hh:mm
    return `${hours == '00' ? '' : hours + 'h'} ${minutes}min`;
  }
}
