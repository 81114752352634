import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'firstWord',
  standalone: true
})
export class GetFirstWordPipe implements PipeTransform
{
    transform(value: string | undefined | null): string {
        if (!value) { return ''; }
        return value.split(' ')[0];
      }
}