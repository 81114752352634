import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, ViewEncapsulation, NgModule } from '@angular/core';
import { SharedModule } from 'primeng/api';
import { RouterModule } from '@angular/router';

/**
 * FloatLabel appears on top of the input field when focused.
 * @group Components
 */
const _c0 = ["*"];
class FloatLabel {
  static ɵfac = function FloatLabel_Factory(t) {
    return new (t || FloatLabel)();
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: FloatLabel,
    selectors: [["p-floatLabel"]],
    ngContentSelectors: _c0,
    decls: 2,
    vars: 0,
    consts: [[1, "p-float-label"]],
    template: function FloatLabel_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵelementStart(0, "span", 0);
        i0.ɵɵprojection(1);
        i0.ɵɵelementEnd();
      }
    },
    encapsulation: 2,
    changeDetection: 0
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FloatLabel, [{
    type: Component,
    args: [{
      selector: 'p-floatLabel',
      template: `
        <span class="p-float-label">
            <ng-content></ng-content>
        </span>
    `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None
    }]
  }], null, null);
})();
class FloatLabelModule {
  static ɵfac = function FloatLabelModule_Factory(t) {
    return new (t || FloatLabelModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: FloatLabelModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [CommonModule, SharedModule, RouterModule, SharedModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FloatLabelModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, SharedModule, RouterModule],
      exports: [FloatLabel, SharedModule],
      declarations: [FloatLabel]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { FloatLabel, FloatLabelModule };
